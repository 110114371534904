<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <br />
    <div style="text-align: center">
      <b-button class="waitAddress" @click="openAddModal()">Adicionar</b-button>
    </div>
    <br />
    <b-table
      striped
      hover
      :items="ordersFiltered"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(order_id)="data">
        <b-link @click="routeToOrder(data.item.order_id)">
          <span v-if="data.item.priority == false" style="background-color: green; color: white">{{
            data.item.id
          }}</span>
          <span v-else>{{ data.item.order_id }}</span>
        </b-link>
      </template>
      <template #cell(ticket_id)="data">
        <input type="text" class="form-control" id="new_name_cat" v-model="data.item.ticket_id" required />
      </template>
      <template #cell(next_email)="data">
        <input type="date" class="form-control" id="new_name_cat" v-model="data.item.next_email_date" required />
      </template>
      <template #cell(actions)="data">
        <b-button @click="updateEmailCarina(data.item)" variant="success">Atualizar</b-button>
        <b-button @click="deleteEmailCarina(data.item)" variant="danger">Apagar</b-button>
      </template>
    </b-table>
    <b-modal id="add-email" title="Adicionar Emails!" ref="modal_add-email" hide-footer>
      <form v-on:submit.prevent="addEmailCarina()">
        <div class="form-group">
          <label for="new_name">Info</label>
          <input type="text" class="form-control" id="new_name_cat" v-model="add_email" required />
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
    <b-modal id="add-email" title="Atualizar Emails!" ref="modal_update-email" hide-footer>
      <form v-on:submit.prevent="updateEmailCarina()">
        <div class="form-group">
          <label for="new_name">Nova Data</label>
          <input type="date" class="form-control" id="new_name_cat" v-model="new_date" required />
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      orders: [],
      ordersFiltered: [],
      id_select: '',
      tracking_select: '',
      state_select: '',
      page: '',
      rows: '',
      perPage: 10,
      currentPage: 1,
      ticket_id: '',
      order: '',
      fields: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'order_id',
          label: 'Encomenda',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'tracking_id',
          label: 'Tracking',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
          sortable: false,
        },
        {
          key: 'ticket_id',
          label: 'Ticket',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'last_email',
          label: 'Último Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'next_email',
          label: 'Próximo Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Ações',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
      ],
      add_email: '',
      new_date: '',
      update_id: '',
    };
  },
  methods: {
    async openAddModal() {
      this.$refs['modal_add-email'].show();
    },
    async getCarinaEmails() {
      this.loading = true;
      try {
        await this.$store.dispatch('getCarinaEmails');
        this.orders = this.getAllCarinaEmails;
        console.log(this.orders);
        this.ordersFiltered = this.orders;
        this.perPage = this.ordersFiltered.length;
        console.log(this.orders);
      } catch (err) {
        console.log(err);
        alert(err);
      }
      this.loading = false;
    },
    async addEmailCarina() {
      this.loading = true;
      try {
        await this.$store.dispatch('addCarinaEmails', { info: this.add_email });
        this.getCarinaEmails();
      } catch (err) {
        console.log(err);
        alert(err);
      }
      this.$refs['modal_add-email'].hide();
      this.loading = false;
    },
    async updateEmailCarina(order) {
      this.loading = true;
      try {
        await this.$store.dispatch('updateCarinaEmails', {
          id: order.id,
          ticket_id: order.ticket_id,
          next_email: order.next_email_date,
        });
        this.getCarinaEmails();
      } catch (err) {
        console.log(err);
        alert(err);
      }
      this.loading = false;
      this.$swal('Completado', 'Email atualizado!', 'success');
    },
    async deleteEmailCarina(order) {
      this.$swal({
        title: 'Quer eliminar o envio de emails ' + order.id + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eliminar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          try {
            await this.$store.dispatch('deleteCarinaEmails', {
              id: order.id,
            });
            this.getCarinaEmails();
          } catch (err) {
            console.log(err);
            alert(err);
          }
          this.loading = false;
          this.$swal('Completado', 'Email eliminado!', 'success');
        }
      });
    },
    async routeToOrder(id) {
      window.open(`https://${window.location.host}/orders/${id}`, '_blank');
    },
  },
  computed: {
    ...mapGetters(['getAllCarinaEmails']),
  },
  async created() {
    await this.getCarinaEmails();
  },
};
</script>

<style>
.waitAddress {
  margin: 30px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
  align-items: center;
}
.waitAddress:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
</style>
