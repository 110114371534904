<template>
  <!-- COMPONENTE PARA SURGIR QUANDO ESTÁ A CARREGAR ALGUMA COISA -->
  <div class="body">
    <div class="lds-dual-ring"></div>
  </div>
</template>
<script>
export default {
  created() {
    console.log("TESTE LOADING CRIAR");
  },
};
</script>

<style scoped>
.body {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  /* fill the entire page */
  width: 100%;
  height: 100%;
  /* remove the space along the edges */
  margin: 0;
  padding: 0;
  /* position it to start at the top of the page */
  top: 0;
  /* put it behind other content */
  z-index: 90;
  background: rgba(207, 207, 207, 0.596);
  padding-left: -400px;
  left: 0;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(99, 99, 99);
  border-color: rgb(99, 99, 99) transparent rgb(99, 99, 99) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
